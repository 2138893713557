<template>
  <div class="gantt-action-bar">
    <div class="action-bar-wrapper grid-toolbar border-bottom">
      <PriorityNavigation class="gantt-toolbar" v-if="allowManage" ulClassName="gantt-action-bar-uncollapsed-ul">
        <li class="">
          <div class="mr-2">
            <label class="ml-2 mr-1" for="dates">{{ $t('task.control.date') }}</label>
            <b-form-select :disabled="readOnly" v-model="local_dates" :options="dateOptions" @change="datesChanged" class="mw-150 minw-170" size="sm"/>
          </div>
        </li>
        <li>
          <div class="d-flex mr-2">
            <label class="mr-1 gantt-action-bar-datetime-label" for="gab_startDate">{{ $t('task.control.date_from') }}</label>
            <b-form-datepicker :disabled="readOnly" class="date-picker" id="gab_startDate" v-model="local_startDate" @input="dateSelected(false, $event)" size="sm"
              today-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </div>
        </li>
        <li>
          <div class="d-flex mr-2">
            <label class="mr-1 gantt-action-bar-datetime-label" for="gab_endDate">{{ $t('task.control.date_to') }}</label>
            <b-form-datepicker :disabled="readOnly" class="date-picker" id="gab_endDate" v-model="local_endDate" @input="dateSelected(true, $event)" size="sm"
                today-button
                close-button
                hide-header
                :label-today-button="$t('date.today')"
                :label-reset-button="$t('date.reset')"
                :label-close-button="$t('date.close')"
                today-button-variant="primary"
                reset-button-variant="danger" 
                close-button-variant="secondary"
              >
                <template v-slot:button-content="{ }">
                  <font-awesome-icon :icon="['far', 'calendar-days']" />
                </template>
              </b-form-datepicker>
          </div>
        </li>
        <li>
          <div class="d-flex mr-1">
            <label class="mr-1 align-self-baseline" for="gab_timescale">{{ $t('task.control.timescale') }}</label>
            <b-form-select :disabled="readOnly" id="gab_timescale" v-model="local_timescale" :options="scaleOptions" @change="changeTimescale" class="mw-150 align-self-baseline" size="sm"></b-form-select>
          </div>
        </li>
        <li v-if="!hideCriticalPath && !hideFreeFloat" class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="!hideCriticalPath">
          <span id="gab_criticalpath">
            <b-btn :disabled="readOnly" @click="toggleCriticalPath" :pressed="local_criticalPath" class="tool-button">
              <font-awesome-icon :icon="['far', 'calendar-exclamation']"/>
            </b-btn>
          </span>
          <b-popover target="gab_criticalpath" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.control.critical_path') }}
          </b-popover>
        </li>
        <li v-if="canView() && !hideFreeFloat">
          <span id="gab_freefloat">
            <b-btn :disabled="readOnly" @click="toggleFreeFloat" :pressed="local_freeFloat" class="tool-button">
              <font-awesome-icon :icon="['far', 'arrows-left-right']"/>
            </b-btn>
          </span>
          <b-popover target="gab_freefloat" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.control.free_float') }}
          </b-popover>
        </li>
      </PriorityNavigation>
      <div class="menu-toggler" :style="{ top: `${7 + menuTogglerOffsetTop}px` }">
        <b-dropdown class="settings-dropdown" offset="25" no-caret>
          <template slot="button-content">
            <div class="text">
              <font-awesome-icon :icon="['far', 'ellipsis-vertical']"/>
            </div>
          </template>
      
          <template>
            <b-dropdown-item @click="$emit('showSettings')">{{ $t('button.settings') }}</b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>
    

    <PriorityNavigation class="task-toolbar grid-toolbar" v-if="allowManage && !hideSecondRow" ulClassName="task-toolbar grid-toolbar">
        <li v-if="!hideMasterCheckBox">
          <b-form-checkbox :disabled="readOnly || actionProcessing" :id="`BTN_SELECT_${id}`" class="secondary-checkbox select-state" v-model="local_selectAll" :indeterminate="masterCheckboxIndeterminateState" @change="$emit('master-checkbox-changed', $event)"></b-form-checkbox>
          <b-popover :target="`BTN_SELECT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.select') }}
          </b-popover>
        </li>
        <li v-if="canAdd() && !hideTaskAdd">
          <span :id="`BTN_ADD_${id}`">
            <b-btn :disabled="readOnly || actionProcessing" @click="$emit('task-new-open', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.add') }}
          </b-popover>
        </li>
        <li v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="readOnly || disableEdit || actionProcessing" @click="$emit('task-edit-open', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ canEdit()? $t('button.edit') : $t('button.view') }}
          </b-popover>
        </li>
        <li v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="readOnly || disableDelete || actionProcessing" @click="$emit('row-delete', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.delete') }}
          </b-popover>
        </li>
        <li v-if="((canAdd() && !hideTaskAdd) || canView() || canDelete()) && (canEdit(permissionName, ['TASK']) && !(hideTaskOutdent && hideTaskIndent))" class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit(permissionName, ['TASK']) && !hideTaskOutdent">
          <span :id="`BTN_OUTDENT_${id}`">
            <b-btn :disabled="readOnly || disableOutdent || actionProcessing" @click="$emit('task-outdent', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','outdent']"/></b-btn>
          </span>
          <b-popover :target="`BTN_OUTDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.outdent') }}
          </b-popover>
        </li>
        <li v-if="canEdit(permissionName, ['TASK']) && !hideTaskIndent">
          <span :id="`BTN_INDENT_${id}`">
            <b-btn :disabled="readOnly || disableIndent || actionProcessing" @click="$emit('task-indent', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','indent']"/></b-btn>
          </span>
           <b-popover :target="`BTN_INDENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.indent') }}
          </b-popover>
        </li>
        <li v-if="(canAdd() && !hideTaskCopy) || (canEdit() && !hideTaskPaste)" class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canAdd() && !hideTaskCopy">
          <span :id="`BTN_COPY_${id}`">
            <b-btn :disabled="readOnly || disableTaskCopy || actionProcessing" @click="$emit('task-copy', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','copy']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COPY_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.copy') }}
          </b-popover>
        </li>
        <li v-if="canAdd() && !hideTaskPaste">
          <span :id="`BTN_PASTE_${id}`">
            <b-btn :disabled="readOnly || disableTaskPaste || actionProcessing" @click="$emit('task-paste', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','paste']"/></b-btn>
          </span>
          <b-popover :target="`BTN_PASTE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('button.paste') }}
          </b-popover>
        </li>
        <li v-if="(canAdd() && canList('TEMPLATE__PROJECT') && !hideTemplateApply) || (canAdd('TEMPLATE__PROJECT') && canAdd('TEMPLATE__TASK') && !hideTemplateSave)" class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="!hideTemplateApply && canAdd() && canList('TEMPLATE__PROJECT')">
          <span :id="`BTN_APPLY_TEMPLATE_${id}`">
            <b-btn :disabled="readOnly || actionProcessing" @click="$emit('template-apply', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','layer-plus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_APPLY_TEMPLATE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.apply_template') }}
          </b-popover>
        </li>
        <li v-if="!hideTemplateSave && canAdd('TEMPLATE__PROJECT') && canAdd('TEMPLATE__TASK')">
          <span :id="`BTN_SAVE_TEMPLATE_${id}`">
            <b-btn :disabled="readOnly || disableDelete || actionProcessing" @click="$emit('template-save', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far','layer-group']"/></b-btn>
          </span>
          <b-popover :target="`BTN_SAVE_TEMPLATE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.save_template') }}
          </b-popover>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="!hideFileImport && canAdd()">
          <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
            <b-btn :disabled="readOnly || disableWhenMoreThanOne || actionProcessing" @click="$emit('file-import', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
          </span>
          <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.import_document') }}
          </b-popover>
        </li>
        <li>
          <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
            <b-btn :disabled="readOnly || actionProcessing" @click="$emit('file-export', { triggeredByActionBar: true })"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.export_document') }}
          </b-popover>
        </li>
        <li v-if="canEdit() && !(hideScheduling && disableManualScheduleProject && disableAutoScheduleProject)">
          <span readonly class="action-v-divider">|</span>
        </li>
        <li v-if="canEdit() && !(disableManualScheduleProject && disableAutoScheduleProject)">
          <template v-if="disableManualScheduleProject">
            <span :id="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="readOnly || actionProcessing" @click="autoScheduleProjectHandler"><font-awesome-icon :icon="['far','thumbtack']"/></b-btn>
            </span>
            <b-popover :target="`BTN_MANUAL_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.manual_scheduled') }}
            </b-popover>
          </template>
          <template v-if="disableAutoScheduleProject">
            <span :id="`BTN_AUTO_SCHEDULE_PROJECT_${id}`">
              <b-btn :disabled="readOnly || actionProcessing" @click="manualScheduleProjectHandler"><font-awesome-icon :icon="['far','calendar-check']"/></b-btn>
            </span>
            <b-popover :target="`BTN_AUTO_SCHEDULE_PROJECT_${id}`" triggers="hover" placement="top" boundary="viewport">
              {{ $t('task.button.auto_scheduled') }}
            </b-popover>
          </template>
        </li>
        <li v-if="!hideScheduling && canEdit()" class="scheduler" @[schedulerMouseEnterEvent]="onSchedulerOver" @mouseleave="onSchedulerLeave">
          <b-dropdown :disabled="readOnly" :id="`BTN_SCHEDULE_${id}`" ref="scheduler" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'calendar-days']"/>
            </template>
            <template v-if="!readOnly">
              <b-dropdown-item :disabled="disableManualScheduleTask || actionProcessing" @click="manualScheduleTaskHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far','thumbtack']"/><span class="item-label">{{ $t('task.button.manual_schedule_task') }}</span>
              </b-dropdown-item>
              <b-dropdown-item :disabled="disableAutoScheduleTask ||  actionProcessing" @click="autoScheduleTaskHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far','calendar-check']"/><span class="item-label">{{ $t('task.button.auto_schedule_task') }}</span>
              </b-dropdown-item>
              <template v-if="!isTemplate">
              <b-dropdown-divider/>
              <b-dropdown-item :disabled="actionProcessing" @click="scheduleProjectNowHandler" href="#">
                <font-awesome-icon class="item-icon" :icon="['far', 'calendar-days']"/><span class="item-label">{{ $t('task.button.schedule_project_now') }}</span>
              </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </li>
        <li v-if="!hideAutoAssignTasks && canEdit()">
          <span :id="`BTN_AUTO_ASSIGN_STAFF_${id}`">
            <b-btn :disabled="readOnly || !hasAutoAssignTasks || actionProcessing" @click="autoAssignStaff"><font-awesome-icon :icon="['far', 'user-helmet-safety']"/></b-btn>
          </span>
          <b-popover :target="`BTN_AUTO_ASSIGN_STAFF_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.auto_assign_staff') }}
          </b-popover>
        </li>
        <li class="divider">
          <span readonly class="action-v-divider">|</span>
        </li>

        <li v-if="!hideTaskCollapse">
          <span :id="`BTN_COLLAPSE_${id}`">
            <b-btn :disabled="readOnly || actionProcessing || expandLevel === 0" @click="$emit('task-collapse')"><font-awesome-icon :icon="['far', 'magnifying-glass-minus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_COLLAPSE_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.minus') }}
          </b-popover>
        </li>
        <li v-if="!hideTaskExpand">
          <span :id="`BTN_EXPAND_${id}`">
            <b-btn :disabled="readOnly || actionProcessing || expandLevel === maxLevel" @click="$emit('task-expand')"><font-awesome-icon :icon="['far', 'magnifying-glass-plus']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EXPAND_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t('task.button.plus') }}
          </b-popover>
        </li>
        <li v-if="!hideSearch" @[filterMouseEnterEvent]="onFilterOver" @mouseleave="onFilterLeave">
          <b-dropdown :disabled="readOnly" :id="`BTN_FILTER_${id}`" ref="filter" 
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onFilterTextDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="local_filterText !== '' ? 'active' : ''" :icon="['far', 'file-magnifying-glass']"/>
            </template>
            <b-dropdown-form v-if="!readOnly" @submit.stop.prevent class="filter-padding">
              <b-input-group class="search-input">
                <b-form-input v-model="local_filterText" @focus="filterTextFocus = true" @blur="filterTextFocus = false" :placeholder="$t('task.filter')"></b-form-input>
                <b-input-group-append>
                  <b-btn @click="onFilterClear" class="search-append" size="sm" variant="danger"><font-awesome-icon class="search-clear" :icon="['far', 'xmark']"/></b-btn>
                </b-input-group-append>
                  <b-input-group-append>
                    <b-btn @click="onFilterSubmit" class="search-append search-append-bg" size="sm"><font-awesome-icon variant="secondary" :icon="['far', 'magnifying-glass']"/></b-btn>
                  </b-input-group-append>
              </b-input-group>
            </b-dropdown-form>
          </b-dropdown>
        </li>
        <li v-if="!hideSearch" @[badgeFilterMouseEnterEvent]="onBadgeFilterEnter" 
            @[badgeFilterMouseOverEvent]="onBadgeFilterOver" 
            @[badgeFilterMouseLeaveEvent]="onBadgeFilterLeave" 
            @[badgeFilterTouchEndEvent]="onBadgeFilterOver">
          <b-dropdown :disabled="isAccessDenied" :id="`BTN_BADGE_FILTER_${id}`" ref="badgeFilter"
            class="action-bar-dropdown text-filter" 
            toggle-class="text-decoration-none" no-caret
            @hide="onBadgeFilterDropdownHide"
          >
            <template #button-content>
              <font-awesome-icon :class="badgeFilters.length > 0 ? 'active' : ''" :icon="['far', 'filter']"/>
            </template>
            <template v-if="!isAccessDenied">
              <b-dropdown-form @submit.stop.prevent class="filter-padding">
                <TaskViewBadgeFilter :filters="badgeFilters" 
                  :fields="badgeFilterFields" 
                  :fieldValues="badgeFilterFieldValues" 
                  :useLink="true" 
                  @modified="onBadgeFilterModified" 
                  @filterModalOpened="onBadgeFilterModalOpened"
                  @filterModalClosed="onBadgeFilterModalClosed"
                  @fetchFieldOptions="onBadgeFilterFetchOptions"
                  />
              </b-dropdown-form>
            </template>
          </b-dropdown>
        </li>
        <li class="view" @[viewMouseEnterEvent]="onViewOver" @mouseleave="onViewLeave">
          <b-dropdown :disabled="readOnly" :id="`BTN_VIEW_${id}`" ref="view" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far','desktop']"/>
            </template>
            <template v-if="!readOnly">
              <b-dropdown-item @click="showAllColumns" href="#">
                <span class="action-item-label">{{ $t('task.button.all_columns') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showNoColumns" href="#">
                <span class="action-item-label">{{ $t('task.button.no_columns') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item @click="savePreset" href="#">
                <span class="action-item-label">{{ $t('task.button.save_as_gantt_view') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider/>
              <template v-for="(item, index) in views">
                <b-dropdown-item class="action-item" :disabled="actionProcessing" @click="loadViewSettings(item)" href="#" :key="index">
                  <span :id="`viewname${index}`" class="action-item-label-with-icon">{{ item.name }}</span>
                  <b-popover
                    v-if="isEllipsisActive(item.name)"
                    :target="`viewname${index}`"
                    placement="top"
                    boundary="viewport"
                    custom-class="popover-margin"
                    triggers="hover"
                    offset="-100"
                    :content="item.name">
                  </b-popover> 
                  <span>
                    <template v-if="!editPermission(item)">
                      <span :id="`COPY_GANTT_${index}`" class="action-item-icon" 
                          @click.stop.prevent="copyColumnSettings(index, item.name, item)">
                        <font-awesome-icon class="" :icon="['far','copy']"/>
                      </span>
                      <b-popover :target="`COPY_GANTT_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.copy_gantt') }}
                      </b-popover>
                      <span class="action-item-icon position-third" 
                          @[infoMouseEnterEvent]="onInfoOver(index)" @mouseleave="onInfoLeave(index)"
                          :id="`BTN_INFO_${index}`">
                        <font-awesome-icon class="" :icon="['far','circle-info']"/>
                      </span>
                      <b-popover
                        :target="`BTN_INFO_${index}`"
                        :ref="`BTN_INFO_${index}`"
                        :show.sync="showInfo[index]"
                        placement="top"
                        boundary="viewport"
                        triggers="manual"
                        :content="$t('owner', [views[index].owner])">
                      </b-popover>
                    </template>
                    
                    <template v-if="editPermission(item)">
                      <span :id="`SHARE_GANTT_${index}`" class="action-item-icon position-third" 
                          @click.stop.prevent="shareColumnSettings(index, item.name, item)">
                        <font-awesome-icon class="" :icon="[item.defaultView ? 'fas' : 'far','share-nodes']"/>
                      </span>
                      <b-popover :target="`SHARE_GANTT_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.share_gantt') }}
                      </b-popover>
                    </template>

                    <template v-if="editPermission(item)">
                      <span :id="`UPDATE_GANTT_${index}`" class="action-item-icon position-second" 
                          @click.stop.prevent="updateColumnSettings(index, item.name, item)" >
                        <font-awesome-icon class="" :icon="['far', 'floppy-disk']"/>
                      </span>
                      <b-popover :target="`UPDATE_GANTT_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.update_gantt') }}
                      </b-popover>
                    </template>

                    <template v-if="editPermission(item)">
                      <span :id="`DELETE_GANTT_${index}`" class="action-item-icon"
                          @click.stop.prevent="removeColumnSettings(index)" >
                        <font-awesome-icon class="" :icon="['far', 'trash-can']"/>
                      </span>
                      <b-popover :target="`DELETE_GANTT_${index}`" triggers="hover" placement="top" boundary="viewport">
                        {{ $t('task.button.delete_gantt') }}
                      </b-popover>
                    </template>
                  </span>
                </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </li>
        <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :disabled="readOnly" :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group v-if="!readOnly" :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('task', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.task') }}</span><font-awesome-icon class="active-check" v-if="coloring.task" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('stage', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.stage') }}</span><font-awesome-icon class="active-check" v-if="coloring.stage" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="coloring.company = !coloring.company; onColorChange('skill', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('staff', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('resource', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="coloring.resource" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('rebate', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.rebate') }}</span><font-awesome-icon class="active-check" v-if="coloring.rebate" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('file', 'gantt_view_coloring')" href="#">
                <span class="action-item-label">{{ $t('task.coloring.file') }}</span><font-awesome-icon class="active-check" v-if="coloring.file" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </li>
        <li>
          <span :id="`BTN_ENABLE_FLATLIST_${id}`">
            <b-btn @click="toggleFlatListHandler"><font-awesome-icon :icon="['far',flatList ? 'list-tree' : 'list']"/></b-btn>
          </span>
          <b-popover :target="`BTN_ENABLE_FLATLIST_${id}`" triggers="hover" placement="top" boundary="viewport">
            {{ $t(flatList ? 'task.button.tree' : 'task.button.flatlist') }}
          </b-popover>
        </li>
    </PriorityNavigation>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import { taskViewService, profileService } from '@/services';
import { isEllipsisActive } from '@/helpers';

export default {
  name: 'GanttActionBar',
  components: {
    PriorityNavigation,
    TaskViewBadgeFilter: () => import('@/components/Filter/TaskViewBadgeFilter.vue')
  },
  props: {
    dates: {
      type: String,
      defaul: null
    },
    //Expected Date format in string: 'YYYY-MM-DD'
    startDate: {
      type: String,
      default: null
    },
    //Expected Date format in string: 'YYYY-MM-DD'
    endDate: {
      type: String,
      default: null
    },
    timescale: {
      type: String,
      default: null
    },
    criticalPath: {
      type: Boolean,
      default: false
    },
    freeFloat: {
      type: Boolean,
      default: false
    },
    filterText: {
      type: String,
      default: null
    },
    expandLevel: {
      type: Number,
      default: 0
    },
    maxLevel: {
      type: Number,
      default: -1
    },
    hideOptProjectStartToEnd: {
      type: Boolean,
      default: false
    },
    hideOptProjectSchedule: {
      type: Boolean,
      default: false
    },
    hideOptTaskSchedule: {
      type: Boolean,
      default: false
    },
    hideOptProjectsSchedule: {
      type: Boolean,
      default: true
    },
    allowManage: {
      type: Boolean,
      default: true
    },
    actionProcessing: {
      type: Boolean,
      default: false
    },
    disableEdit: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    disableOutdent: {
      type: Boolean,
      default: false
    },
    disableIndent: {
      type: Boolean,
      default: false
    },
    disableTaskCopy: {
      type: Boolean,
      default: false
    },
    disableTaskPaste: {
      type: Boolean,
      default: false
    },
    disableWhenMoreThanOne: {
      type: Boolean,
      default: false
    },    
    disableManualScheduleTask: {
      type: Boolean,
      default: false
    },
    disableAutoScheduleTask: {
      type: Boolean,
      default: false
    },
    disableManualScheduleProject: {
      type: Boolean,
      default: false
    },
    disableAutoScheduleProject: {
      type: Boolean,
      default: false
    },
    hasAutoAssignTasks: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    hideMasterCheckBox: {
      type: Boolean,
      default: true
    },
    masterCheckboxState: {
      type: Number,
      default: -1
      //Possible values:
      //-1: unchecked
      //0: indeterminate
      //1: checked
    },
    hideTaskOutdent: {
      type: Boolean,
      default: false
    },
    hideTaskIndent: {
      type: Boolean,
      default: false
    },
    hideTaskAdd: {
      type: Boolean,
      default: false
    },
    hideSecondRow: {
      type: Boolean,
      default: false
    },
    hideTaskCopy: {
      type: Boolean,
      default: false
    },
    hideTaskPaste: {
      type: Boolean,
      default: false
    },
    hideTemplateApply: {
      type: Boolean,
      default: false
    },
    hideTemplateSave: {
      type: Boolean,
      default: false
    },
    hideFileImport: {
      type: Boolean,
      default: false
    },
    hideScheduleMenu: {
      type: Boolean,
      default: false
    },
    hideCriticalPath: {
      type: Boolean,
      default: false
    },
    hideFreeFloat: {
      type: Boolean,
      default: false
    },
    hideScheduling: {
      type: Boolean,
      default: false
    },
    hideAutoAssignTasks: {
      type: Boolean,
      default: false
    },
    hideTaskCollapse: {
      type: Boolean,
      default: false
    },
    hideTaskExpand: {
      type: Boolean,
      default: false
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    permissionName: {
      type: String,
      default: 'TASK'
    },
    views: {
      type: Array,
      default: () => { return [] }
    },
    coloring: {
      type: Object,
      default: () => { return {} }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    flatList: {
      type: Boolean,
      default: false
    },
    isAccessDenied: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: String,
      default: null
    },
    badgeFilters: {
      type: Array,
      default: () => { return [] }
    },
    customFields: {
      type: Array,
      default: () => []
    },
    menuTogglerOffsetTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      id: 'AgGridGanttActionBar',
      userId: null,
      local_dates: null,
      local_startDate: null,
      local_endDate: null,
      local_timescale: null,
      local_criticalPath: false,
      local_freeFloat: false,
      subMenu: {
        autoSchedulingShow: false
      },
      local_selectAll: false,
      local_filterText: '',
      filterTextFocus: false,
      
      //BadgeFilter related
      badgeFilterFields: [
        { value: 'complexity', text: 'Complexity' }
        , { value: 'closeTime', text: 'Finish Date' }  
        , { value: 'description', text: 'Description' }
        , { value: 'identifier', text: 'Identifier' }
        , { value: 'parentTasks', text: 'Parent Tasks' }
        , { value: 'priority', text: 'Priority' }
        , { value: 'progress', text: 'Progress' }  
        , { value: 'rebateName', text: 'Rebate Name' }
        , { value: 'resourceName', text: 'Resource Name' }
        , { value: 'skillName', text: 'Skill Name' }
        , { value: 'staffName', text: 'Staff Name' }
        , { value: 'stageName', text: 'Stage Name' }
        , { value: 'startTime', text: 'Start Date' }    
        , { value: 'tagName', text: 'Tag Name' }
        , { value: 'taskName', text: 'Task Name' }
        , { value: 'type', text: 'Type' }
      ], 
      badgeFilterFieldValues: {
        // taskName: [
        //   { text: 'Task #1' }
        //   , { text: 'Task #2' }
        // ],
        // skillName: [
        //   { text: 'Rigging' },
        //   { text: 'Layout' },
        //   { text: 'Animation' }
        // ]
      }, 
      badgeFilterFocus: false, 
      badgeFilterModalOpened: 'close',
      showInfo: []
    }
  },
  created() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.updateDataValue('local_dates', this.dates);
    this.updateDataValue('local_startDate', this.startDate);
    this.updateDataValue('local_endDate', this.endDate);
    this.updateDataValue('local_timescale', this.timescale);
    this.updateDataValue('local_criticalPath', this.criticalPath);
    this.updateDataValue('local_freeFloat', this.freeFloat);
    this.updateDataValue('local_filterText', this.filterText != null? this.filterText : '');
    this.initSingleBadgeFilterValues();
  },
  mounted() {
  
    //BadgeFilter related
    document.addEventListener('click', this.toggleBadgeFilterFocus);
    document.addEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    
  },
  beforeDestroy() {
  
    //BadgeFilter related
    document.removeEventListener('keydown', this.handleBadgeFilterEscapeKeyDown)
    document.removeEventListener('click', this.toggleBadgeFilterFocus);
    this.badgeFilterFocus = false;
    this.badgeFilterModalOpened = 'close';
    
  },
  watch: {
    'dates': function(newValue) {
      this.updateDataValue('local_dates', newValue);
    },
    'startDate': function(newValue) {
      this.updateDataDateValue('local_startDate', newValue);
    },
    'endDate': function(newValue) {
      this.updateDataDateValue('local_endDate', newValue);
    },
    'timescale': function(newValue) {
      this.updateDataValue('local_timescale', newValue);
    },
    'criticalPath': function(newValue) {
      this.updateDataValue('local_criticalPath', newValue);
    },
    'freeFloat': function(newValue) {
      this.updateDataValue('local_freeFloat', newValue);
    },
    'filterText': function(newValue) {
      this.updateDataValue('local_filterText', newValue != null? newValue : '');
    },
    masterCheckboxState(newValue) {
      this.processMasterCheckboxState(newValue);
    },
    customFields(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        for (const c of newValue) {
          this.badgeFilterFields.push({
            value: c.name
            , text: c.displayName
          })
        }
      }
    },
    views(newValue) {
      this.showInfo = [];
      for (const v of newValue) {
        this.showInfo.push(false);
      }
    }
  },
  computed: {         
    infoMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    badgeFilterMouseOverEvent() {
      return this.isTouchDevice()? null : 'mouseover';
    },
    badgeFilterMouseLeaveEvent() {
      return this.isTouchDevice()? null : 'mouseleave';
    },
    badgeFilterTouchEndEvent() {
      return this.isTouchDevice()? 'touchend' : null;
    },
    scaleOptions() {
      return [
        { text: this.$t('timescale.day'), value: "day" },
        { text: this.$t('timescale.week'), value: "week" },
        { text: this.$t('timescale.month'), value: "month" },
        { text: this.$t('timescale.year'), value: "year" }
      ]
    },
    dateOptions() {
      const options = [
        { text: this.$t('view_dates.custom'),  value: null},
        // - means the project's start date and end date attributes (if exist).  
        // If both don't exist, then error msg "Project start date or end date 
        // not defined." and From/To dates don't change.
        { text: this.$t('view_dates.project_start_to_end'), value: "project-start-to-end" },
        // - means the earliest task start date to the latest tasks finish date 
        // in the project (hopefully from Chris' macro?)
        { text: this.$t(this.isTemplate? 'view_dates.template_schedule' : 'view_dates.project_schedule'), value: "project-schedule" },
        // all projects schedules, the earliest project start date to the latest project finish date
        
        { text: this.$t('view_dates.projects_schedule'), value: "projects-schedule" },
        // - means the earliest task start date to the latest tasks finish date 
        // among all listed task
        { text: this.$t('view_dates.task_schedule'), value: "task-schedule" },
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: this.$t('view_dates.this_week'), value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: this.$t('view_dates.this_week_to_date'), value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: this.$t('view_dates.this_month'), value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_month_to_date'), value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: this.$t('view_dates.this_quarter'), value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: this.$t('view_dates.this_quarter_to_date'), value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: this.$t('view_dates.this_year'), value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: this.$t('view_dates.this_year_to_date'), value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_week'), value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_week_to_date'), value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: this.$t('view_dates.last_month'), value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: this.$t('view_dates.last_month_to_date'), value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: this.$t('view_dates.last_quarter'), value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: this.$t('view_dates.last_quarter_to_date'), value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: this.$t('view_dates.last_year'), value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: this.$t('view_dates.next_week'), value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_4_weeks'), value: "next-4-weeks" },
        // - means next 8 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_8_weeks'), value: "next-8-weeks" },
        // - means next 12 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_12_weeks'), value: "next-12-weeks" },
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: this.$t('view_dates.next_24_weeks'), value: "next-24-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: this.$t('view_dates.next_month'), value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: this.$t('view_dates.next_quarter'), value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: this.$t('view_dates.next_year'), value: "next-year" } 
      ]

      if(this.hideOptProjectStartToEnd) {
        const idx = options.findIndex(i => i.value === 'project-start-to-end');
        options.splice(idx, 1); //Remove 'project-start-to-end' option as template project does not have start/end date.
      }
      if(this.hideOptProjectSchedule) {
        const idx = options.findIndex(i => i.value === 'project-schedule');
        options.splice(idx, 1); //Remove 'project-schedule' option as template project does not have start/end date.
      }
      if(this.hideOptTaskSchedule) {
        const idx = options.findIndex(i => i.value === 'task-schedule');
        options.splice(idx, 1); // This option is only applicable to data view's gantt. Hide for other use case.
      }
      if(this.hideOptProjectsSchedule) {
        const idx = options.findIndex(i => i.value === 'projects-schedule');
        options.splice(idx, 1); //Remove 'projects-schedule' option as template project does not have start/end date.
      }
      
      return options;
    },
    schedulerMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    viewMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    filterMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    masterCheckboxIndeterminateState() {
      return this.masterCheckboxState == 0;
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    processMasterCheckboxState(newValue) {
      if(newValue < 1) {
        this.local_selectAll = false;
      } else {
        this.local_selectAll = true;
      }
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      // include the 'heartz' as a way to have a non matching MQ to help terminate the join
      // https://git.io/vznFH
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    updateDataDateValue(key, value) {
      const localValue = this[key] ? moment(this[key], 'YYYY-MM-DD') : null;
      if(localValue !== value) {
        this[key] = value;
      }
    },
    updateDataValue(key, value) {
      if(this[key] !== value) {
        this[key] = value;
      }
    },
    datesChanged() {
      this.$emit('datesChanged', this.local_dates);
    },
    dateSelected(isEndDate=false) {
      if (isEndDate) {
        // EndDate
        if(this.endDate == null) {
          if(this.local_endDate && this.local_endDate.length > 0) {
            const _date = this.local_endDate;
            this.$emit('endDateChanged', _date);
          }
        } else { //prop endDate is not null
          if(this.local_endDate == null || this.endDate !== this.local_endDate) {
            const _date = this.local_endDate && this.local_endDate.length > 0? this.local_endDate : null;
            this.$emit('endDateChanged', _date);
          }
        }
      } else {
        // StartDate
        if(this.startDate == null) {
          if(this.local_startDate && this.local_startDate.length > 0) {
            const _date = this.local_startDate;
            this.$emit('startDateChanged', _date);
          }
        } else { //prop startDate is not null
          if(this.local_startDate == null || this.startDate !== this.local_startDate) {
            const _date = this.local_startDate && this.local_startDate.length > 0? this.local_startDate : null;
            this.$emit('startDateChanged', _date);
          }
        }
      }
    },
    changeTimescale() {
      this.$emit('update:timescale', this.local_timescale);
    },
    toggleCriticalPath() {
      this.$emit('update:criticalPath', !this.local_criticalPath);
    },
    toggleFreeFloat() {
      this.$emit('update:freeFloat', !this.local_freeFloat);
    },
    manualScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleProject) {
        this.$emit('manual-schedule-project', { triggeredByActionBar: true });  
      }
    },
    autoScheduleProjectHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleProject) {
        this.$emit('auto-schedule-project', { triggeredByActionBar: true });  
      }
    },
    manualScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableManualScheduleTask) {
        this.$emit('manual-schedule-task', { triggeredByActionBar: true });  
      }
    },
    autoScheduleTaskHandler(event) {
      event.preventDefault();
      if(!this.disableAutoScheduleTask) {
        this.$emit('auto-schedule-task', { triggeredByActionBar: true });  
      }
    },
    scheduleProjectNowHandler(event) {
      event.preventDefault();
      this.$emit('schedule-project-now', { triggeredByActionBar: true });
    },
    autoAssignStaff(event) {
      event.preventDefault();
      if(this.hasAutoAssignTasks && !this.actionProcessing) {
        this.$emit('auto-assign-staff', {  triggeredByActionBar: true });
      }
    },
    toggleFlatListHandler(event) {
      event.preventDefault();
      this.$emit('toggle-flat-list', { triggeredByActionBar: true });
    },
    showAllColumns() {
      this.$emit('all-columns', { triggeredByActionBar: true });  
    },
    showNoColumns() {
      this.$emit('no-columns', { triggeredByActionBar: true });  
    },
    loadViewSettings(view) {
      this.$emit('load-view-settings', { triggeredByActionBar: true, view: view });  
    },
    copyColumnSettings(index, name, profile) {
      this.$emit('copy-columns', { triggeredByActionBar: true, index: index, name: name, profile: profile }); 
    },
    shareColumnSettings(index, name, profile) {
      this.$emit('share-columns', { triggeredByActionBar: true, index: index, name: name, profile: profile }); 
    },
    updateColumnSettings(index, name, profile) {
      this.$emit('update-columns', { triggeredByActionBar: true, index: index, name: name, profile: profile }); 
    },
    removeColumnSettings(index) {
      this.$emit('remove-columns', { triggeredByActionBar: true, index: index });  
    },
    editPermission(view) {
      if (typeof view.editingPermissions === 'undefined') {
        return true;    
      }
      
      return view.editingPermissions.includes(this.userId);
    },
    savePreset(event) {
      event.preventDefault();
      this.$emit('save-columns', { triggeredByActionBar: true });  
    },
    onSchedulerOver() {
      this.$refs.scheduler.visible = true;
    },
    onSchedulerLeave() {
      this.$refs.scheduler.visible = false;
    },
    onViewOver() {
      this.$refs.view.visible = true;
    },
    onViewLeave(arg) {
      if (arg == null || arg.toElement == null || (typeof arg.toElement.className === 'string' &&
          arg.toElement.className !== 'arrow' &&
          arg.toElement.className && !arg.toElement.className.startsWith('popover'))) {
        this.$refs.view.visible = false;
      }
    },
    onFilterOver() {
      this.$refs.filter.visible = true;
    },
    onFilterLeave() {
      this.$refs.filter.visible = false;
    },
    onFilterTextDropdownHide(bvEvent) {
      if(this.filterTextFocus){
        bvEvent.preventDefault();
      }
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    onColorChange(val, color_key) {
      this.$emit('color-change', { triggeredByActionBar: true, val: val, color_key: color_key });  
    },
    onFilterSubmit() {
      this.filterTextFocus = false;
      this.$refs.filter.visible = false;
      this.$emit('filterTextChanged', this.local_filterText);
    },
    onFilterClear() {
      this.local_filterText = '';
      this.$emit('filterTextChanged', this.local_filterText);
    },
    onBadgeFilterModified(filter) {
      this.badgeFilterFocus = true; //Pin the badgeFilter when a change is made.
      this.$emit('badgeFilterModified', { badgeFilterFieldValues: this.badgeFilterFieldValues, filter: filter });
      this.badgeFilterFieldValues = JSON.parse(JSON.stringify(this.badgeFilterFieldValues)); //Force triggering vue reactivity
    },
    onBadgeFilterDropdownHide(bvEvent) {
      if (this.badgeFilterFocus || this.badgeFilterModalOpened != 'close') {
        bvEvent.preventDefault();
      }
    },
    onBadgeFilterEnter() {
      this.$refs.badgeFilter.visible = true;
    },
    onBadgeFilterOver(evt) {
      if (this.$refs.badgeFilter?.$el.id != null && evt.target.closest('.dropdown-toggle') != null && this.badgeFilterModalOpened != 'open' && this.badgeFilterFocus) {
        const id = evt.target.closest('.dropdown-toggle').id;
        if (id != null && id.startsWith(this.$refs.badgeFilter?.$el.id)) {
          this.badgeFilterFocus = false; 
        }
      }
    },
    onBadgeFilterLeave() {
      if (!this.badgeFilterFocus) {
        this.$refs.badgeFilter.visible = false;
      }
    },
    onBadgeFilterModalOpened() {
      this.badgeFilterModalOpened = 'open';
      this.badgeFilterFocus = true;
    },
    onBadgeFilterModalClosed() {
      this.badgeFilterModalOpened = 'signaled-close';
    },
    toggleBadgeFilterFocus(evt) {
      if (this.badgeFilterModalOpened == 'signaled-close') {
        this.badgeFilterModalOpened = 'close';
      } else if (this.badgeFilterFocus && this.badgeFilterModalOpened == 'close' && (this.$refs.badgeFilter?.$el?.id == null || evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) == null)) {
        this.badgeFilterFocus = false;
      } else if (!this.badgeFilterFocus && this.$refs.badgeFilter && evt.target.closest(`#${this.$refs.badgeFilter.$el.id}`) != null) {
        this.badgeFilterFocus = true;
      }
    },
    handleBadgeFilterEscapeKeyDown(e) {
      const evt = e || window.event;
      if (evt.keyCode === 27 && this.badgeFilterFocus) {
        this.badgeFilterFocus = false;
        this.badgeFilterModalOpened = 'close';
        this.closePriorityNavDropdown = true;
      }
    },
    onBadgeFilterFetchOptions(payload) {
      let serviceCall = null;
      if (payload.field == 'taskName') {
        serviceCall = taskViewService.getUniqueTaskName(this.projectId)
      } else if (payload.field == 'identifier') {
        serviceCall = taskViewService.getUniqueIdentifier(this.projectId)
      } else if (payload.field == 'parentTasks') {
        serviceCall = taskViewService.getUniqueParentTasks(this.projectId)
      } else if (payload.field == 'rebateName') {
        serviceCall = taskViewService.getUniqueRebateName(this.projectId)
      } else if (payload.field == 'resourceName') {
        serviceCall = taskViewService.getUniqueResourceName(this.projectId)
      } else if (payload.field == 'skillName') {
        serviceCall = taskViewService.getUniqueSkillName(this.projectId)
      } else if (payload.field == 'staffName') {
        serviceCall = taskViewService.getUniqueStaffName(this.projectId)
      } else if (payload.field == 'stageName') {
        serviceCall = taskViewService.getUniqueStageName(this.projectId)
      } else if (payload.field == 'tagName') {
        serviceCall = taskViewService.getUniqueTagName(this.projectId)
      } else if (payload.field == 'type') {
        serviceCall = taskViewService.getUniqueType(this.projectId)
      } else if (payload.field == 'priority') {
        serviceCall = taskViewService.getUniquePriority(this.projectId)
      } else if (payload.field == 'complexity') {
        serviceCall = taskViewService.getUniqueComplexity(this.projectId)
      } else if (this.customFields.find(i => i.name == payload.field) != null) {
        serviceCall = taskViewService.getTaskUniqueCustomFieldName(this.projectId, payload.field)
      }
      if (serviceCall != null) {
        serviceCall
        .then(data => {
          if (data.length > 0 && this.badgeFilters.length > 0) {
            const found = this.badgeFilters.find(i => i.field == payload.field);
            if (found != null && Array.isArray(found.value) && found.value.length > 0) {
              this.$set(this.badgeFilterFieldValues, payload.field, data.map(i => ({ 
                text: payload.field === 'type' && i === 'Project' ? 'Summary Task' : !i ? '(Empty)' : i
                , checked: found.value.find(j => j.text != null && j.text.localeCompare(!i ? '(Empty)' : i, undefined, { sensitivity: 'base' }) == 0) != null
              })));
              return;
            }
          } 
          this.$set(this.badgeFilterFieldValues, payload.field, data.map(i => ({ text: payload.field === 'type' && i === 'Project' ? 'Summary Task' : !i ? '(Empty)' : i, checked: false })));
        });
      } else {
        this.badgeFilterFieldValues[payload.field] = [];
      }
    },
    onInfoOver(index) {
      profileService.nodeList(this.views[index].uuId).then((response) => {
        this.views[index].owner = response.data.resultList.filter(v => this.views[index].editingPermissions.includes(v.uuId)).map(r => { return r.name }).join(", ");
        this.$set(this.showInfo, index, true);
      });
    },
    onInfoLeave(index) {
      this.$set(this.showInfo, index, false);
    },
    isEllipsisActive(text) {
      return isEllipsisActive(text, this);
    },
    initSingleBadgeFilterValues() {
      const singleValues = ['startTime',
                            'closeTime',
                            'progress',
                            'description'
                           ];
                         
      // these value are single values to be entered by the user
      for (const val of singleValues) {
        this.badgeFilterFieldValues[val] = null;
      }     
    }
  }
}
</script>

<style lang="scss" scoped>

.grid-toolbar {
  height: 45px;
  
  .btn {
    font-size: 1rem;
  }
  ul {
    margin: 0;
  }
}

.task-toolbar {
  height: 46px;
}

.gantt-action-bar {
  border: 1px solid var(--border-medium);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.tool-button {
  background: transparent;
  border: none;
  font-size: 1rem;
  
}

.mw-150 {
  max-width: 150px;
}

.minw-170 {
  min-width: 170px;
}

.action-v-divider {
  cursor: default;
}


.gantt-action-bar-datetime-label {
  line-height: 27px;
  margin-bottom: 0;
}

.gantt-action-bar-uncollapsed-ul > li {
  line-height: 42px;
}

.search-input {
  padding-top: 2px;
  padding-bottom: 2px;
}

.grid-toolbar .btn.btn-secondary.search-append-bg {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--form-control-addon-bg) !important;
}

// .grid-toolbar .action-bar-wrapper {
//   position: relative;
//   z-index: 1000;
// }

// .grid-toolbar .action-bar-wrapper .menu-toggler .settings-dropdown .dropdown-item {
//   padding: 4px 20px;
// }

// .grid-toolbar .action-bar-wrapper .btn.btn-secondary.dropdown-toggle {
//   color: var(--grid-toolbar-button);
//   background-color: transparent;
//   border: none;
//   top: -4px;
// }
</style>

<style lang="scss">
.grid-toolbar.action-bar-wrapper .menu-toggler .settings-dropdown .dropdown-item {
  padding: 4px 20px;
}
.grid-toolbar.action-bar-wrapper .btn.btn-secondary.dropdown-toggle {
  color: var(--grid-toolbar-button);
  background-color: transparent;
  border: none;
  top: -4px;
}
</style>
